import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Home from './pages/Home';
import LemeForm from './pages/LemeForm';

const Routes = ({ theme, toggleTheme }) => (
    <BrowserRouter>
        <Switch>
            <Route exact path="/" render={(props) => <Home theme={theme} toggleTheme={toggleTheme} />}/>
            <Route path="/formulario" component={LemeForm} />
        </Switch>
    </BrowserRouter>
);

export default Routes;
