import { ServicesSessionContainer, BoxServices, BoxesGrid, SessionTitle } from '../styles/sessions/ServicesSession'
import { Link } from 'react-router-dom'

const ServicesSession = ({ animate }) => {
    return (
        <ServicesSessionContainer>
            <SessionTitle animate={animate}>
                <p>Nossos Serviços</p>
                <h3>Veja Abaixo os Serviços Disponibilizados<br/> por Nossa Equipe</h3>
            </SessionTitle>
            <BoxesGrid animate={animate}>
                <BoxServices>
                    <p><Link to="/formulario">Internet</Link></p>
                    <p>Sua internet com mais velocidade e diversão pra sua família!</p>
                </BoxServices>

                <BoxServices>
                    <p><Link to="/formulario">LemeTV</Link></p>
                    <p>Filmes, séries, esportes e muito mais, em um só lugar.</p>
                </BoxServices>

                <BoxServices>
                    <p><Link to="/formulario">Casa Inteligente</Link></p>
                    <p>Câmeras de Monitoramento, Videoporteiro e Sistema de Alarme</p>
                </BoxServices>
            </BoxesGrid>
        </ServicesSessionContainer>
    )
}

export default ServicesSession
