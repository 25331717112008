import styled from 'styled-components'

export const PlansContainer = styled.div`
    width: 100%;
    position: relative;
    padding: 2rem 0;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.background};
`

export const SessionTitle = styled.div`
    width: 85%;
    text-align: center;

    >p {
        font-weight: 300;
        font-size: 1rem;
        text-transform: uppercase;
        color: ${props => props.theme.colors.yellow};
        opacity: 0;
        letter-spacing: .1rem;
        animation: plansTitle .5s forwards;
    }
    
    >h3{
        font-weight: 200;
        font-size: 1.5rem;
        color: ${props => props.theme.colors.white};
        text-align: center;
        margin-bottom: 2rem;
        opacity: 0;
        letter-spacing: 0;
        animation: plansTitle .5s forwards;
    }

    >h3 span {
        font-weight: 600;
    }

    @keyframes plansTitle {
        from {
            opacity: 0;
            letter-spacing: .5rem;
        }
        to {
            opacity: 1;
            letter-spacing: 0;
        }
    }

    @media (max-width: 460px) {
        >h3 {
            font-size: 1.75rem;
        }
    }
`
