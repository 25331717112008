import { useForm } from 'react-hook-form'

import ArrowIcon from '../assets/icons/right-arrow.svg'
import { AreaNewsletter } from '../styles/sessions/FooterSession';

const FormNewsletter = () => {
  const { handleSubmit, register } = useForm();

  async function handleSendForm(data) {
      alert('Em breve essa funcionalidade estará disponível!')
  }

  return (
    <AreaNewsletter>
        <h3>Receba<br/> Novidades</h3>
        <p>Inscreva-se abaixo em nossa newsletter<br/> e receba nossas novidades em seu email</p>
        <form onSubmit={handleSubmit(handleSendForm)}>
            <input
                {...register('email')}
                id="email"
                name="email"
                type="email"
                required
                placeholder="Digite seu email"
            />
            <button type="submit">
                <img src={ArrowIcon} alt=""/>
            </button>
        </form>
    </AreaNewsletter>
  )
}

export default FormNewsletter;
