import styled from 'styled-components'

export const LemeIconBluredOne = styled.img`
    filter: blur(7px);
    transform: scale(1);
    position: absolute;
    right: -175px;
    top: 400px;
    z-index: 1050;
    animation: lemeicon 1.4s ease-in-out;

    @keyframes lemeicon {
         from {
          transform: rotate(0);
         }
         to {
          transform: rotate(360deg);
         }
    }

    @media (max-width: 768px) {
         display: none;
    }
`

export const LemeIconBluredTwo = styled.img`
     filter: blur(7px);
     transform: scale(.8);
     position: absolute;
     left: -120px;
     top: 800px;
     z-index: 1001;

     @media (max-width: 768px) {
         display: none;
    }
`

export const LemeIconBluredThree = styled.img`
     filter: blur(7px);
     transform: scale(1.3);
     position: absolute;
     right: -210px;
     top: 1600px;
     z-index: 1001;

     @media (max-width: 768px) {
         display: none;
    }
`

export const LemeIconBluredFour = styled.img`
     filter: blur(7px);
     transform: scale(.7);
     position: absolute;
     left: 0;
     top: 2050px;
     transform: translateX(-150px);
     z-index: 0;

     @media (max-width: 768px) {
         display: none;
    }
`

export const LemeIconBluredFive = styled.img`
     filter: blur(7px);
     transform: scale(1);
     position: absolute;
     right: 0;
     top: 79%;
     transform: translateX(150px);
     z-index: 0;

     @media (max-width: 768px) {
         display: none;
    }
`