import { Link } from 'react-router-dom'

import AnatelImage from '../assets/images/anatel.png'
import WhatsappIcon from '../assets/icons/whatsapp.svg'
import EmailIcon from '../assets/icons/email.svg'
import InstagramIcon from '../assets/icons/instagram.svg'
import LocationIcon from '../assets/icons/location.svg'

import {
    FooterSessionContainer,
    FooterSessionContent,
    AreaContato,
    AreaSeviços,
    Section
} from "../styles/sessions/FooterSession"

import FormNewsletter from '../components/FormNewsletter'

const FooterSession = () => {
    return (
        <FooterSessionContainer name="contato">
            <FooterSessionContent>
                <Section>
                    <FormNewsletter />

                    <AreaContato>
                        <h3>Contato</h3>
                        <div>
                            <a href="https://api.whatsapp.com/send?phone=5527999371728" target="_blank" rel="noreferrer">
                                <img src={WhatsappIcon} alt="" />
                                <p>(27) 99937-1728</p>
                            </a>
                        </div>
                        <div>
                            <a href="/">
                                <img src={EmailIcon} alt="" />
                                <p>contato@lemeinternet.com.br</p>
                            </a>
                        </div>
                        <div>
                            <a href="https://instagram.com/lemeinternet" target="_blank" rel="noreferrer">
                                <img src={InstagramIcon} alt="" />
                                <p>@lemeinternet</p>
                            </a>
                        </div>
                        <div>
                            <a href="https://www.google.com/maps/place/R.+Ant%C3%B4nio+Guedes+Alcoforado,+818+-+Centro,+Pedro+Can%C3%A1rio+-+ES,+29970-000/@-18.3006022,-39.9558529,18z/data=!3m1!4b1!4m5!3m4!1s0xca804c7c19ffdb:0x2cfb5450c106be80!8m2!3d-18.3006037!4d-39.9552022?hl=pt-BR" target="_blank" rel="noreferrer">
                                <img src={LocationIcon} alt="" />
                                <p>
                                    Rua Antônio Guedes Alcoforado, 818<br />
                                    <span>Centro, Pedro Canário - ES | 29970-000</span><br />
                                    Em frente ao Mercardo Municipal
                                </p>
                            </a>
                        </div>
                    </AreaContato>

                    <AreaSeviços>
                        <h3>Serviços</h3>
                        <a href="https://central.lemeinternet.com.br" target="_blank" rel="noreferrer">Central do Cliente</a>
                        <a href="https://central.lemeinternet.com.br" target="_blank" rel="noreferrer">Segunda Via Online</a>
                        <Link to="/formulario">Contrate Agora</Link>
                        <div>
                            <img src={AnatelImage} alt="" />
                            <p>Provedor Autorizado</p>
                        </div>
                    </AreaSeviços>
                </Section>
                <Section>
                    <p>© Todos os Direitos Reservados | <strong>Leme Internet</strong></p>
                    <p>Desenvolvido por <a href="https://instagram.com/samcarvalhos" target="_blank" rel="noreferrer"><strong>SCarvalhos</strong></a></p>
                </Section>
            </FooterSessionContent>
        </FooterSessionContainer>
    )
}

export default FooterSession
