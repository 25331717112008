import styled from 'styled-components'

export const ButtonsContainer = styled.div`
    position: absolute;
    left: 0;
    bottom: -4rem;
    
    display: flex;
    margin-top: 1rem;
    box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.045);
    z-index: 1001;

    @media (max-width: 580px) {
        width: 100%;
        flex-direction: column;
    }
`;

export const Button = styled.div`    
    >a {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: .75rem 1rem;
        
        background: ${props => props.background};
        color: ${props => props.color};
        border-radius:${props => props.borderRadius};
        
        cursor: pointer;
        transition: .3s;
        
        >p{
            pointer-events: none;
            font-size: .8rem;
            font-weight: 300;

            >span {
                font-weight: 600;
            }

            @media (max-width: 580px) {
                font-size: 1rem;
            }
        }
        
        >img {
            margin-right: .5rem;
        }

        :hover {
            filter: brightness(.85);
        }

        @media (max-width: 580px) {
            border-radius: .25rem;
        }
    }

    @media (max-width: 580px) {
        width: 100%;
        margin-bottom: .5rem;
        font-size: 1rem;
    }
`;
