const light = {
    title: 'light',

    colors: {
        yellow: "#F8C60F",
        background: "#f0f0f0",
        secondback: "#f8f8f8",
        threeback: "#fff",

        text: "#444",
        secondtext: "#999",
        white: "#000",
        
        headerback: "rgba(0, 0, 0, .8)",
        headerborder: "none",
        headerblur: "blur(10px)",
        
        border: "#eaeaea",
        colorselect: "#fcfcfc",
    }
}

export default light;
