import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import VideoOne from '../assets/videos/01.mp4'
import VideoTwo from '../assets/videos/02.mp4'
import VideoThree from '../assets/videos/03.mp4'
import VideoFour from '../assets/videos/04.mp4'
import VideoFive from '../assets/videos/05.mp4'
import VideoSix from '../assets/videos/06.mp4'

import { TestimonyVideo, Container } from '../styles/components/TestimonySlider';
import 'swiper/swiper-bundle.css'

SwiperCore.use([ Pagination ]);

const TestimonySlider = ({ animate }) => {
  return (
    <Container animate={animate}>
      <Swiper
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          480:{
            slidesPerView: 2,
          },
          640:{
            slidesPerView: 3,
          }
        }}
        spaceBetween={40}
        slidesPerView={3}
        pagination={{clickable: true}}
        style={{ padding: "2rem" }}
      >
        <SwiperSlide>
          <TestimonyVideo
            src={VideoOne}
            type="video/mp4"
            controls
            />
        </SwiperSlide>

        <SwiperSlide>
          <TestimonyVideo
            src={VideoTwo}
            type="video/mp4"
            controls
          />
        </SwiperSlide>

        <SwiperSlide>
          <TestimonyVideo
            src={VideoThree}
            type="video/mp4"
            controls
          />
        </SwiperSlide>

        <SwiperSlide>
          <TestimonyVideo
            src={VideoFour}
            type="video/mp4"
            controls
          />
        </SwiperSlide>

        <SwiperSlide>
          <TestimonyVideo
            src={VideoFive}
            type="video/mp4"
            controls
          />
        </SwiperSlide>

        <SwiperSlide>
          <TestimonyVideo
            src={VideoSix}
            type="video/mp4"
            controls
          />
        </SwiperSlide>
      </Swiper>
    </Container>
  );
};

export default TestimonySlider;
