import PlansContent from '../components/PlansContent'
import { PlansContainer, SessionTitle } from '../styles/sessions/PlansSession'

const PlansSession = () => {
    return (
        <PlansContainer name="planos">
                <SessionTitle>
                    <p>Planos</p>
                    <h3>#<span>Navegue</span>ComA<span>Leme</span></h3>
                </SessionTitle>
                <PlansContent/>
        </PlansContainer>
    )
}

export default PlansSession
