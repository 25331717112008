import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
    *{
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        font-family: "Poppins", sans-serif;
    }
  
    ::-webkit-scrollbar-track {
        background-color: #181818;
    }

    ::-webkit-scrollbar {
        width: 22px;
        background: #181818;
    }

    ::-webkit-scrollbar-thumb {
        background: linear-gradient(45deg, rgb(80, 80, 80), rgb(128, 128, 128));
        border-radius: 1rem;
        border: 4.5px solid #181818;
    }
    
    @media(max-width: 1080px) {
        html {
            font-size: 93.75%;
        }
    }
    
    @media(max-width: 945px) {
        html {
            font-size: 87.5%;
        }
    }
    
    @media(max-width: 882px) {
        html {
            font-size: 81.25%;
        }
    }
    
    @media(max-width: 648px) {
        html {
            font-size: 75%;
        }
    }
    
    @media(max-width: 558px) {
        html {
            font-size: 68.75%;
        }
    }
    
    body {
        width: 100%;
        overflow-x: hidden;
        background: ${props => props.theme.colors.background};
    }
    
    body, button {
        font-family: "Poppins", sans-serif;
    }
    
    body, input, textarea, button {
        font: 400 1rem 'Poppins', sans-serif;
    }
    
    button {
        cursor: pointer;
    }
    
    a {
        color: inherit;
        text-decoration: none;
    }
`
