import TestimonySlider from '../components/TestimonySlider'

import {
    TestimonySessionContainer,
    TestimonySessionContent,
    TittleSession,
} from "../styles/sessions/TestimonySession"

const TestimonySession = ({ animate }) => {
    return (
        <TestimonySessionContainer>
            <TestimonySessionContent>
                <TittleSession animate={animate}>
                    <p>Depoimentos</p>
                    <h3>O Que Nossos Clientes Estão Achando</h3>
                </TittleSession>
                <TestimonySlider animate={animate}/>
            </TestimonySessionContent>
        </TestimonySessionContainer>
    )
}

export default TestimonySession
