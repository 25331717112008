import styled from 'styled-components'

export const PlansContentContainer = styled.div`
    width: 85%;
    max-width: 1010px;
    
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
        gap: 6px;

    animation: animatePlansContainer 1s forwards;

    @keyframes animatePlansContainer {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    @media (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
        gap: 4px;
    }

    @media (max-width: 584px) {
        grid-template-columns: 1fr;
        justify-items: center;
    }
`
