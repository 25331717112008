import {
    CoverageSessionContainer,
    CoverageSessionContent,
    BlockContent,
    TittleSession,
    DistrictList,
    CovarageSelect
} from "../styles/sessions/CoverageSession"

import MapLeme from "../components/Map"

const CoverageSession = ({ animateOnScroll, theme }) => {
    return (
        <CoverageSessionContainer name="cobertura">
            <CoverageSessionContent animate={animateOnScroll}>
                <MapLeme theme={theme}/>
                <BlockContent animate={animateOnScroll}>
                    <TittleSession animate={animateOnScroll}>
                            <p>Cobertura</p>
                            <h3>Conheça as Regiões Que Já Possuem Nossa Cobertura</h3>
                    </TittleSession>
                    <DistrictList>
                        <CovarageSelect defaultValue="00">
                            <option value="00" disabled>Regiões Disponíveis:</option>
                            <option value="01">Pedro Canário</option>
                            <option value="02">Braço do Rio</option>
                        </CovarageSelect>
                    </DistrictList>
                </BlockContent>
            </CoverageSessionContent>
        </CoverageSessionContainer>
    )
}

export default CoverageSession
