import styled, { css } from 'styled-components'

export const Container = styled.div`
    width: 85%;
    ${props => props.animate === "1" && css`
        opacity:  0;
        transform: scale(1.3);
        animation: boxesTestimony .5s forwards;

        @keyframes boxesTestimony {
            to {
                opacity: 1;
                transform: scale(1);
            }
        }`
    }

    ${props => props.animate === "0" && css`
        opacity:  1;
        transform: scale(1);
        animation: boxesTestimonyNone .5s forwards;

        @keyframes boxesTestimonyNone {
            to {
                opacity: 0;
            }
        }`
    }
`

export const TestimonyVideo = styled.video`
    width: 100%;
    border-radius: .5rem;
    border: none;
    outline: none;
`
