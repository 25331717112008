import LemeIconBlur from '../assets/icons/leme-icon-blur.svg'
import { LemeIconBluredOne, LemeIconBluredTwo, LemeIconBluredThree, LemeIconBluredFour, LemeIconBluredFive } from '../styles/components/LemeIconBlur'

const LemeIcons = () => {
    return (
        <>
            <LemeIconBluredOne src={LemeIconBlur} />
            <LemeIconBluredTwo src={LemeIconBlur} />
            <LemeIconBluredThree src={LemeIconBlur} />
            <LemeIconBluredFour src={LemeIconBlur} />
            <LemeIconBluredFive src={LemeIconBlur} />
        </>
    )
}

export default LemeIcons
