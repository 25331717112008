import styled, { css } from 'styled-components'

export const TestimonySessionContainer = styled.div`
    width: 100%;
    margin: 3rem 0;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
`

export const TestimonySessionContent = styled.div`
    width: 85%;
    max-width: 1010px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const TittleSession = styled.div`
    width: 85%;
    text-align: center;

    >p {
        font-weight: 300;
        font-size: 1rem;
        text-transform: uppercase;
        color: ${props => props.theme.colors.yellow};
        letter-spacing: .1rem;
    }
    
    >h3{
        font-weight: 600;
        font-size: 1.5rem;
        color: ${props => props.theme.colors.white};
        text-align: center;
    }

    ${props => props.animate === "1" && css`
        >p {
            opacity: 0;
            letter-spacing: .5rem;
            animation: tittleTestimony .5s forwards;
        }

        >h3 {
            opacity: 0;
            letter-spacing: .5rem;
            animation: tittleTestimony .5s forwards;
        }

        @keyframes tittleTestimony {
            to {
                opacity: 1;
                letter-spacing: 0;
            }
        }`
    }

    ${props => props.animate === "0" && css`
        >p {
            opacity: 1;
            letter-spacing: 0;
            animation: tittleTestimonyNone .5s forwards;
        }

        >h3 {
            opacity: 1;
            letter-spacing: 0;
            animation: tittleTestimonyNone .5s forwards;
        }

        @keyframes tittleTestimonyNone {
            to {
                opacity: 0;
            }
        }`
    }

    @media (max-width: 460px) {
        >h3 {
            font-size: 1.75rem;
        }
    }
`
