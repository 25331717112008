import styled, { css } from 'styled-components'

export const ServicesSessionContainer = styled.div`
    width: 100%;
    padding: 2rem 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: ${props => props.theme.colors.background};
`

export const SessionTitle = styled.div`
    width: 85%;
    text-align: center;
    margin: 0 0 1rem 0;

    >p {
        font-weight: 300;
        font-size: 1rem;
        text-transform: uppercase;
        color: ${props => props.theme.colors.yellow};
        opacity: 0;
        letter-spacing: .1rem;
    }
    
    >h3{
        font-weight: 600;
        font-size: 1.5rem;
        color: ${props => props.theme.colors.white};
        text-align: center;
        opacity: 0;
        letter-spacing: 0rem;
    }

    ${props => props.animate === "1" && css`
        >p {
            opacity: 0;
            letter-spacing: .5rem;
            animation: titleServices .5s forwards;
        }

        >h3 {
            opacity: 0;
            letter-spacing: .5rem;
            animation: titleServices .5s forwards;
        }

        @keyframes titleServices {
            to {
                opacity: 1;
                letter-spacing: 0;
            }
        }`
    }

    ${props => props.animate === "0" && css`
        >p {
            opacity: 1;
            letter-spacing: 0;
            animation: titleServicesNone .5s forwards;
        }

        >h3 {
            opacity: 1;
            letter-spacing: 0;
            animation: titleServicesNone .5s forwards;
        }

        @keyframes titleServicesNone {
            to {
                opacity: 0;
            }
        }`
    }

    @media (max-width: 460px) {
        >h3 {
            font-size: 1.75rem;
        }
    }
`

export const BoxesGrid = styled.div`
    width: 85%;
    max-width: 1010px;
    margin-top: 1rem;
    margin-bottom: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    ${props => props.animate === "1" && css`
        opacity:  0;
        transform: scale(1.3);
        animation: boxesGrid .5s forwards;

        @keyframes boxesGrid {
            to {
                opacity: 1;
                transform: scale(1);
            }
        }`
    }

    ${props => props.animate === "0" && css`
        opacity:  1;
        transform: scale(1);
        animation: boxesGridNone .5s forwards;

        @keyframes boxesGridNone {
            to {
                opacity: 0;
            }
        }`
    }

    @media (max-width: 584px) {
        grid-template-columns: 1fr 1fr;
    }
`

export const BoxServices = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    margin: .1rem;
    background: ${props => props.theme.colors.secondback};
    border-radius: .25rem;
    box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.045);
    transition: ease-in-out .3s;

    :hover {
        transform: scale(1.025);
    }

    >p:first-child {
        font-size: 1.1rem;
        font-weight: 600;
        color: ${props => props.theme.colors.white};
        margin-top: 1rem;
        margin-bottom: -.25rem;

    }

    >p {
        margin: 1rem 1.5rem 1.5rem 1.5rem;
        font-size: .9rem;
        font-weight: 400;
        color: ${props => props.theme.colors.text};
        cursor: default;
    }
`
