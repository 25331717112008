import styled, { css } from 'styled-components'

export const CoverageSessionContainer = styled.div`
    width: 100%;
    position: relative;
    margin: 2rem 0;

    display: flex;
    justify-content: center;
    align-items: center;
`

export const CoverageSessionContent = styled.div`
    width: 85%;
    max-width: 1010px;

    display: flex;
    justify-content: center;
    align-items: center;

    .leaflet-container {
        width: 100%;
        height: 40vh;
        object-fit: cover;
        border-radius: .25rem;
    }

    ${props => props.animate === "1" && css`
        .leaflet-container  {
            opacity:  0;
            transform: translateX(-100px);
            animation: imageCoverage .5s forwards;
        }

        @keyframes imageCoverage {
            to {
                opacity: 1;
                transform: translateX(0);
            }
        }`
    }

    ${props => props.animate === "0" && css`
        .leaflet-container  {
            opacity:  1;
            transform: translateX(0);
            animation: imageCoverageNone .5s forwards;
        }

        @keyframes imageCoverageNone {
            to {
                opacity: 0;
                transform: translateX(-100);
            }
        }`
    }

    @media (max-width: 584px) {
        flex-direction: column-reverse;

        .leaflet-container {
            width: 100%;
            height: 150px;
        }
    }
`

export const BlockContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-left: 1.5rem;

    ${props => props.animate === "1" && css`
        opacity:  0;
        transform: translateX(100px);
        animation: blockContent .5s forwards;

        @keyframes blockContent {
            to {
                opacity: 1;
                transform: translateX(0);
            }
        }`
    }

    ${props => props.animate === "0" && css`
        opacity:  1;
        transform: translateX(0);
        animation: blockContentNone .5s forwards;

        @keyframes blockContentNone {
            to {
                opacity: 0;
                transform: translateX(100);
            }
        }`
    }

    @media (max-width: 584px) {
        padding-left: 0;
    }
`

export const TittleSession = styled.div`
    margin-bottom: 2rem;
    
    >p {
        font-weight: 300;
        font-size: 1rem;
        text-transform: uppercase;
        color: ${props => props.theme.colors.yellow};
        letter-spacing: .1rem;
    }

    >h3 {
        font-weight: 600;
        font-size: 1.5rem;
        color: ${props => props.theme.colors.white};
    }

    @media (max-width: 584px) {
        text-align: center;
        >h3 {
            font-size: 1.75rem;
        }
    }
`

export const DistrictList = styled.div`
    width: 100%;
    position: relative;

    &:after {
        content: "▼";
        width: 2rem;
        color: ${props => props.theme.colors.colorselect};
        background: ${props => props.theme.colors.yellow};
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: .25rem;
        pointer-events: none;
        transition: .4s;
    }

    @media (max-width: 584px) {
        display: flex;
        justify-content: center;
        align-items: center;

        margin-bottom: 1.5rem;
    }
`

export const CovarageSelect = styled.select`
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border: none;
    cursor: pointer;

    width: 100%;

    border-radius: .25rem;
    padding: .5rem 1rem;
    background: ${props => props.theme.colors.secondback};
    box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.045);
    color: ${props => props.theme.colors.secondtext};
    transition: .3s;

    >option {
        border: none;
        outline: none;
        background: none;
        margin: 40px;

        &:hover {
            background: ${props => props.theme.colors.yellow};
        }
    }
`
