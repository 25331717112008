import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    height: 50px;

    display: flex;
    justify-content: center;
    align-items: center;

    position: fixed;
    top: 0;
    left: 0;

    background: ${props => props.theme.colors.headerback};
    border: ${props => props.theme.colors.headerborder};
    backdrop-filter: ${props => props.theme.colors.headerblur};

    z-index: 1100;

    >div {
        width: 85%;
        max-width: 1010px;
        height: 100%;

        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    >div >a {
        height: 100%;
        display: flex;
        align-items: center;
    }

    >div >a >img {
        width: 36px;
        height: 36px;

        cursor: pointer;
        animation: lemeicon 1.4s ease-in-out;
    }
`

export const Menu = styled.nav`
    z-index: 100;

    ul {
        display: flex;
        align-items: center;
    }

    ul a {
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
        font-size: .75rem;
        color: #fcfcfc;
        
        margin: 0 .75rem;
        padding: .5rem .75rem;

        cursor: pointer;
        transition: .3s;

        :hover {
            color: ${props => props.theme.colors.yellow};
        }
    }

    @media (max-width: 580px) {
        width: 100%;
        height: 70vh;
        background: ${props => props.theme.colors.secondback};
        box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.045);

        position: absolute;
        top: 49px;
        right: 0;

        display: flex;
        justify-content: center;
        align-items: center;
        animation: menu .2s ease-out;

        @keyframes menu {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }

        ul {
            width: 100%;
            height: 85%;

            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
        }

        ul a {
            width: 100%;
            padding-left: 3rem;
            border-bottom: 1px solid ${props => props.theme.colors.border};
            border-radius: .25rem;
            color: ${props => props.theme.colors.secondtext};
            font-size: 1.75rem;
        }

        display: ${props => props.display};

    }
`

export const MenuIconMobile = styled.button`
    outline: none;
    border: none;
    background: none;
    display: none;

    >img {
        width: 26px;
        transform: rotate(180deg);
    }

    @media (max-width: 580px) {
        display: flex;
    }
`
