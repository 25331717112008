import Slider from '../components/Slider';
import { HomeSessionContainer } from '../styles/sessions/HomeSession';

const HomeSession = () => {
    return (
        <HomeSessionContainer name="home">
            <Slider />
        </HomeSessionContainer>
    );
}

export default HomeSession;
