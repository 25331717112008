import styled from 'styled-components'

export const PlanContainer = styled.div`
    width: 99%;
    height: 350px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    background: ${props => props.theme.colors.secondback};

    box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.045);
    border-radius: .25rem;
    border-top: 3px solid transparent;

    opacity: 0.8;
    transition: .3s;

    :hover {
        border-top: 3px solid ${props => props.theme.colors.yellow};
        transform: scale(1.1);
        opacity: 1 !important;
        z-index: 1005;
    }

    @media (max-width: 584px) {
        width: 70%;
        margin: .75rem 0;

        :nth-child(2) {
            margin: 0;
        }
    }

    @media (max-width: 460px) {
        width: 100%;
    }
`

export const TitleArea = styled.div`
    width: 100%;
    padding: 1rem 1rem 0 1rem;
    border-bottom: 1px solid ${props => props.theme.colors.border};

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    >p {
        font-size: .75rem;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: .2rem;
        color: ${props => props.theme.colors.secondtext};
        margin-bottom: -0.25rem;
    }

    >h2 {
        font-size: .75rem;
        font-weight: 500;
        color: ${props => props.theme.colors.white};
    }

    >h2 span {
        font-size: 1.75rem;
        font-weight: 600;
    }
    

    @media (max-width: 768px) {
        >p{
            font-size: 1rem;
        }
        >h2 span {
            font-size: 2.5rem;
        }

        >h2 {
            font-size: 1rem;
            margin-bottom: 0;
        }
    }

    @media (max-width: 460px) {
        >p{
            font-size: 1.25rem;
        }
        >h2 span {
            font-size: 3rem;
        }

        >h2 {
            font-size: 1.5rem;
            margin-bottom: 0;
        }
    }
`

export const DescriptionArea = styled.div`
    width: 100%;
    padding: 1rem;
    position: relative;

    >p {
        font-weight: 400;
        font-size: .75rem;
        line-height: 1.75rem;
        text-align: center;
        color: ${props => props.theme.colors.secondtext};
        z-index: 1001;

        :last-child {
            line-height: 1.1rem;
        }
    }

    >p span {
        font-weight: 700;
    }

    @media (max-width: 768px) {
        >p{
            font-size: 1rem;
            line-height: 2rem;
        }
    }

    @media (max-width: 460px) {
        >p{
            font-size: 1.25rem;
            line-height: 3rem;
        }
    }
`

export const FooterArea = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    >div {
        width: 80%;
    }

    >p {
        font-size: .6rem;
        color: ${props => props.theme.colors.text};
        margin-top: .75rem;
        margin-bottom: 1.25rem;
    }

    @media (max-width: 460px) {
        >p{
            font-size: 1rem;
        }
    }
`

export const PlanButton = styled.div`
    width: 100%;
    padding: .5rem;
    
    display: flex;
    justify-content: center;
    align-items: center;
    
    background: ${props => props.theme.colors.yellow};
    border-radius: .25rem;
    margin-bottom: .25rem;

    font-weight: 600;
    font-size: .75rem;
    color: ${props => props.theme.colors.white};

    transition: .3s;

    :hover {
        filter: brightness(.9);
    }

    @media (max-width: 460px) {
        height: 4rem;
        font-size: 1.5rem;
    }
`

export const FaleConoscoButton = styled.a`
    width: 100%;
    padding: .5rem;

    display: flex;
    justify-content: center;
    align-items: center;

    background: ${props => props.theme.colors.threeback};

    border-radius: .25rem;
    font-size: .7rem;
    font-weight: 600;

    transition: .3s;

    :hover {
        filter: brightness(.9);
    }

    >img {
        width: 16px;
        margin-right: .5rem;
        margin-top: -2px;
    }

    @media (max-width: 460px) {
        height: 4rem;
        font-size: 1.5rem;
    }
`
