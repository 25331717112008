import { useState, useEffect } from 'react'

import Header from '../components/Header'
import LemeIcons from '../components/LemeIcons'

import HomeSession from "../sessions/HomeSession"
import PlansSession from '../sessions/PlansSession'
import BenefitsSession from '../sessions/BenefitsSession'

import { AppContainer } from '../styles/Home'
import CoverageSession from '../sessions/CoverageSession'
import AboutSession from '../sessions/AboutSession'
import TestimonySession from '../sessions/TestimonySession'
import ServicesSession from '../sessions/ServicesSession'
import FooterSession from '../sessions/FooterSession'

function Home({ theme, toggleTheme }) {
  const [animateBenefitsSession, setAnimateBenefitsSession  ] = useState("0");
  const [animateOnScroll, setAnimateOnScroll  ] = useState("0");
  const [animateAboutSession, setAnimateAboutSession  ] = useState("0");
  const [animateTestimonySession, setAnimateTestimonySession  ] = useState("0");
  const [animateServicesSession, setAnimateServicesSession  ] = useState("0");

  const handleScroll = () => {
    const scrollTopPosition = document.documentElement.scrollTop;

    if(scrollTopPosition > 600) {
      setAnimateBenefitsSession('1')
    } else {
      setAnimateBenefitsSession('0')
    }

    if(scrollTopPosition > 1000) {
      setAnimateOnScroll('1')
    } else {
      setAnimateOnScroll('0')
    }

    if(scrollTopPosition > 1300) {
      setAnimateAboutSession('1')
    } else {
      setAnimateAboutSession('0')
    }

    if(scrollTopPosition > 1750) {
      setAnimateTestimonySession('1')
    } else {
      setAnimateTestimonySession('0')
    }

    if(scrollTopPosition > 2150) {
      setAnimateServicesSession('1')
    } else {
      setAnimateServicesSession('0')
    }

  }

  useEffect(() => {
    window.onscroll = () => handleScroll();
  }, []);

  return (
    <AppContainer>
        <Header toggleTheme={toggleTheme} />
        <LemeIcons />
        <HomeSession />
        <PlansSession />
        <BenefitsSession animate={animateBenefitsSession} />
        <CoverageSession animateOnScroll={animateOnScroll} theme={theme} />
        <AboutSession animate={animateAboutSession} />
        <TestimonySession animate={animateTestimonySession} />
        <ServicesSession animate={animateServicesSession} />
        <FooterSession toggleTheme={toggleTheme} />
    </AppContainer>
  );
}

export default Home;
