import { BenefitsSessionContainer, BoxBenefits, BoxesGrid, SessionTitle } from '../styles/sessions/BenefitsSession'

const BenefitsSession = ({ animate }) => {
    return (
        <BenefitsSessionContainer>
            <SessionTitle animate={animate}>
                <p>Vantagens</p>
                <h3>Veja Abaixo as Vantagens de Ter Uma Internet Sem Limites</h3>
            </SessionTitle>
            <BoxesGrid animate={animate}>
                <BoxBenefits>
                    <p>Internet Rápida</p>
                    <p>A Leme é o provedor de internet mais rápido de Pedro Canário.</p>
                </BoxBenefits>

                <BoxBenefits>
                    <p>Melhor Custo Benefício</p>
                    <p>Nos outros provedores você só paga menos se contratar serviços adicionais (combo).</p>
                </BoxBenefits>

                <BoxBenefits>
                    <p>Rapidez na Instalação</p>
                    <p>Na Leme sua instalação é executada com rapidez e agilidade.</p>
                </BoxBenefits>

                <BoxBenefits>
                    <p>Sem Limites</p>
                    <p>Não existe qualquer tipo de franquia limitando a utilização da internet. Com a Leme você não tem limites.</p>
                </BoxBenefits>

                <BoxBenefits>
                    <p>Melhor Atendimento</p>
                    <p>A equipe Leme está sempre pronta para te atender, sem enrolação e pertinho de você.</p>
                </BoxBenefits>

                <BoxBenefits>
                    <p>Suporte Técnico</p>
                    <p>Qualidade e agilidade de atendimento com equipe treinada para oferecer a melhor solução pra você.</p>
                </BoxBenefits>
            </BoxesGrid>
        </BenefitsSessionContainer>
    )
}

export default BenefitsSession
