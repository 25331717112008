import { Link } from 'react-router-dom'

import {
    PlanContainer,
    TitleArea,
    DescriptionArea,
    FooterArea,
    PlanButton,
    FaleConoscoButton
} from '../styles/components/Plan'

import WhatsappIcon from '../assets/icons/whatsapp.svg'

const Plan = ({ price, planName, planNumber, planDescription }) => {
    return (
        <PlanContainer>
            <TitleArea>
                <p>{planName}</p>
                <h2><span>R${price},00</span>/{planNumber} Megas</h2>
            </TitleArea>
            <DescriptionArea>
                <p>- 100% Fibra Óptica</p>
                <p>- Downloads e Uploads Rápidos</p>
                <p>- Sem Franquia de Consumo</p>
                <p>- {planDescription}</p>
            </DescriptionArea>
            <FooterArea>
                <div>
                    <Link to="/formulario">
                        <PlanButton>
                            Contrate Aqui
                        </PlanButton>
                    </Link>
                    <FaleConoscoButton
                        href="https://api.whatsapp.com/send?phone=5527999371728"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={WhatsappIcon} alt=""/>
                        Fale Conosco
                    </FaleConoscoButton>
                </div>
                <p>*Consulte disponibilidade</p>
            </FooterArea>
        </PlanContainer>
    )
}

export default Plan
