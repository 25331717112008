import styled, { css } from 'styled-components'

export const AboutSessionContainer = styled.div`
    width: 100%;
    position: relative;
    margin: 2rem 0;

    display: flex;
    justify-content: center;
    align-items: center;
`

export const AboutSessionContent = styled.div`
    width: 85%;
    max-width: 1010px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    >img {
        width: 50%;
        height: 40vh;
        object-fit: cover;
        object-position: center;
        background: ${props => props.theme.colors.secondback};
        border-radius: .25rem;
    }

    ${props => props.animate === "1" && css`
        >img {
            opacity:  0;
            transform: translateX(100px);
            animation: imageAbout .5s forwards;
        }

        @keyframes imageAbout {
            to {
                opacity: 1;
                transform: translateX(0);
            }
        }`
    }

    ${props => props.animate === "0" && css`
        >img {
            opacity:  1;
            transform: translateX(0);
            animation: imageAboutNone .5s forwards;
        }

        @keyframes imageAboutNone {
            to {
                opacity: 0;
                transform: translateX(100);
            }
        }`
    }

    @media (max-width: 584px) {
        flex-direction: column;

        >img {
            width: 100%;
            height: 150px;
            margin-bottom: 2rem;
        }
    }
`

export const BlockText = styled.div`
    width: 45%;

    ${props => props.animate === "1" && css`
        opacity:  0;
        transform: translateX(-100px);
        animation: blockText .5s forwards;

        @keyframes blockText {
            to {
                opacity: 1;
                transform: translateX(0);
            }
        }`
    }

    ${props => props.animate === "0" && css`
        opacity:  1;
        transform: translateX(0);
        animation: blockTextNone .5s forwards;

        @keyframes blockTextNone {
            to {
                opacity: 0;
                transform: translateX(-100);
            }
        }`
    }

    @media (max-width: 584px) {
        width: 100%;
    }

`

export const Text = styled.div`
    >p {
        font-weight: 300;
        font-size: .85rem;
        color: ${props => props.theme.colors.secondtext};
    }

    @media (max-width: 584px) {
        width: 100%;
        text-align: center;
        >p {
            font-size: 1.25rem;
        }
    }
`

export const TittleSession = styled.div`
    margin-bottom: .5rem;
    
    >p {
        font-weight: 300;
        text-transform: uppercase;
        color: ${props => props.theme.colors.yellow};
        letter-spacing: .1rem;
    }

    >h3 {
        font-weight: 600;
        font-size: 1.5rem;
        color: ${props => props.theme.colors.white};
    }


    @media (max-width: 584px) {
        text-align: center;
        >h3 {
            font-size: 1.75rem;
        }
    }
`

export const SocialMedias = styled.div`
    width: 100%;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-top: 1rem;
    margin-left: -6px;
    @media (max-width: 584px) {
        text-align: center;
        margin-bottom: 3rem;
        justify-content: center;
    }
`

export const SocialButton = styled.a`
    width: 36px;
    height: 36px;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-right: 1rem;
    cursor: pointer;
    transition: .3s;

    >img {
        width: 28px;
        pointer-events: none;
    }

    :hover >img {
        transform: scale(1.1);
    }
`
