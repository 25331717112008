const dark = {
    title: 'dark',

    colors: {
        yellow: "#F8C60F",
        background: "#101010",
        secondback: "#181818",
        threeback: "#202020",

        text: "#444",
        secondtext: "#999",
        white: "#fcfcfc",
        
        headerback: "linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.01) 100%)",
        headerborder: "1px solid rgba(255, 255, 255, 0.025)",
        headerblur: "blur(10px)",
        
        border: "#202020",
        colorselect: "#333",
    }
}

export default dark;
