import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import Leaflet from 'leaflet'

import LemeIcon from '../assets/icons/leme-location-icon.svg'

import "leaflet/dist/leaflet.css"
import '../styles/components/MapLeme.css'

const urlTileLayer = `https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_ACCESS_TOKEN_MAP_BOX}`;

const MapLeme = () => {
    const position = [-18.300546169676387, -39.95518063120933]

    const mapPinIcon = Leaflet.icon({
           iconUrl: LemeIcon,
           iconSize: [32, 32],
           iconAnchor: [28, 28],
           popupAnchor: [-12, -28],
    });

    return (
        <MapContainer center={position} zoom={15} scrollWheelZoom={true}>
            <TileLayer url={urlTileLayer}/>
            <Marker position={position} icon={mapPinIcon}>
                <Popup>
                    <strong>Leme Internet</strong><br/>
                    Centro, Pedro Canário - ES
                </Popup>
            </Marker>
        </MapContainer>
    )
}

export default MapLeme
