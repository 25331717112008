import styled from 'styled-components'

export const FooterSessionContainer = styled.footer`
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props => props.theme.colors.secondback};
    box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.045);
`

export const FooterSessionContent = styled.div`
    width: 85%;
    max-width: 1010px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

`

export const Section = styled.div`
    width: 100%;
    display: flex;
    
    &:first-child {
        width: 100%;
        justify-content: space-between;
        padding-top: 2rem;

        @media (max-width: 584px) {
            flex-direction: column;
            width: 85%;
        }
    }
    &:last-child {
        width: 100%;
        /* height: 20%; */
        padding: 1rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: .8rem;
        font-weight: 300;
        color: ${props => props.theme.colors.secondtext};

        >p {
            opacity: .4;
        }
    }
`

export const AreaNewsletter = styled.div`
    padding: 1rem;
    >h3 {
        font-size: 2rem;
        line-height: 1.75rem;
        margin-bottom: 1rem;
        color: ${props => props.theme.colors.white};
    }
    >p {
        margin-bottom: 1rem;
        color: ${props => props.theme.colors.secondtext};
    }

    >form {
        width: 100%;
        display: flex;
    }

    >form >input {
        width: 90%;
        outline: none;
        border: none;
        border-radius: .25rem 0 0 .25rem;
        padding: .5rem 1rem;
        background: ${props => props.theme.colors.background};
        color: ${props => props.theme.colors.secondtext};
    }
    
    >form >button {
        width: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        padding: .5rem 1rem;
        background: ${props => props.theme.colors.yellow};
        color: #fcfcfc;
        border-radius: 0 .25rem .25rem 0;
        filter: brightness(.8);
        transition: .3s;

        &:hover {
            filter: brightness(1);
        }

        >img {
            width: 12px;
        }
    }
`


export const AreaSeviços = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;

    >h3 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
        color: ${props => props.theme.colors.white};
    }
    >a {
        font-size: .9rem;
        color: ${props => props.theme.colors.secondtext};
        margin-bottom: .5rem;

        :hover{
            filter: brightness(.7);
        }
    }

    >div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        margin-top: 1rem;

        >img {
            width: 70px;
        }

        >p {
            font-size: .6rem;
            font-weight: 400;
            color: ${props => props.theme.colors.secondtext};
            cursor: default;
        }
    }
`

export const AreaContato = styled.div`
    padding: 1rem;

    >h3 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
        color: ${props => props.theme.colors.white};
    }

    >div {
        margin-bottom: .5rem;
        
        >a {
            display: flex;
            align-items: flex-start;


            >img {
                width: 20px;
                margin-right: .5rem;
            }
    
            >p {
                font-size: .9rem;
                color: ${props => props.theme.colors.secondtext};
    
                >span {
                    font-weight: 600;
                }
                
                :hover{
                    filter: brightness(.7);
                }
            }
        }
    }
`
