import SwiperCore, { Pagination, Autoplay, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Buttons from './Buttons';

import FirstBackImage from '../assets/images/01.png';
import SecondBackImage from '../assets/images/02.png';
import ThreeBackImage from '../assets/images/05.png';

import { SlideCotainer, SlideContent } from '../styles/components/Slider';

import 'swiper/swiper-bundle.css'

SwiperCore.use([ Pagination, Autoplay, EffectFade]);

const Slider = () => {
  return (
      <Swiper
        autoplay={{delay: 7000}}
        speed={700}
        spaceBetween={0}
        slidesPerView={1}

        pagination={{clickable: true}}
        effect="fade"
      >
        <SwiperSlide style={{ width: "100vw", height: "65vh" }}>
          <SlideCotainer backimage={FirstBackImage}>
              <SlideContent>
                  <p>#<span>Navegue</span>ComA<span>Leme</span></p>
                  <h2>Navegue <span>Tranquilo</span><br/>Com a Leme</h2>
                  {/* <p>Somos comprometidos com <span>qualidade</span> no serviço e <br/><span>exelência</span> no atendimento.</p> */}
                  <Buttons />
              </SlideContent>
          </SlideCotainer>
        </SwiperSlide>

        <SwiperSlide style={{ width: "100vw", height: "65vh" }}>
          <SlideCotainer backimage={SecondBackImage}>
              <SlideContent>
                  <p>#<span>Navegue</span>ComA<span>Leme</span></p>
                  <h2>
                    Internet <span>Ultra Rápida</span><br/>Para Se Conectar
                  </h2>
                  <Buttons />
              </SlideContent>
          </SlideCotainer>
        </SwiperSlide>

        <SwiperSlide style={{ width: "100vw", height: "65vh" }}>
          <SlideCotainer backimage={ThreeBackImage}>
              <SlideContent>
                  <p>#<span>Navegue</span>ComA<span>Leme</span></p>
                  <h2>Internet Que <span>Não Cai</span><br/>Quando Chove</h2>
                  <Buttons />
              </SlideContent>
          </SlideCotainer>
        </SwiperSlide>
      </Swiper>
  );
};

export default Slider;
