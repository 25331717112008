import styled from 'styled-components'

export const SlideCotainer = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    background-image: url(${props => props.backimage});
    background-position: center center;
    background-size: cover;
`

export const SlideContent = styled.div`
    width: 85%;
    max-width: 1010px;
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    >p {
        font-weight: 300;
        font-size: 1rem;
        color: #fcfcfc;
    }
    
    >p span {
        font-weight: 600;
    }

    >p:first-child {
        margin-bottom: .15rem;
    }

    >h2 {
        font-size: 3.5rem;
        font-weight: 300;
        letter-spacing: -.15rem;
        line-height: 3.5rem;
        color: #fcfcfc;
    }
    
    >h2 span {
        color: ${props => props.theme.colors.yellow};
    }
    
    @media (max-width: 580px) {
        align-items: center;

        h2 {
            font-size: 2.75rem;
            text-align: center;
            margin-bottom: 5rem;
        }

        >p {
            font-size: 1.5rem;
        }
    }
`
