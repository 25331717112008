import {
    AboutSessionContainer,
    AboutSessionContent,
    BlockText,
    Text,
    SocialMedias,
    TittleSession,
    SocialButton
} from "../styles/sessions/AboutSession"

import InstagramIcon from '../assets/icons/instagram.svg'
import FacebookIcon from '../assets/icons/facebook.svg'
import LemeImage from '../assets/images/leme.png'

const AboutSession = ({ animate }) => {
    return (
        <AboutSessionContainer name="sobre">
            <AboutSessionContent animate={animate}>
                <BlockText animate={animate}>
                    <Text>
                        <TittleSession>
                            <p>Sobre a Leme</p>
                            <h3>Quem Somos</h3>
                        </TittleSession>
                        <p>
                        Um sonho despretensioso que virou realidade! Em 2020 os sócios <strong>Arthur Caio</strong> e <strong>Sandro</strong> compartilharam o desejo de empreender e entregar ao mercado uma solução que fizesse sentido aos clientes, assim, logo nas primeiras reuniões foi definido um dos pilares da Leme, o foco na experiência dos usuários.<br/><br/>
                        Encontro após encontro ficava mais claro que a empresa deveria ser mais que uma empresa de conectividade, ela teria que exercer um papel de influência, direção e orientação aos clientes, e assim surgiu o nome: Leme (peça plana, localizada na parte submersa da popa de uma embarcação, que gira em um eixo e determina a direção em que aponta a proa).<br/><br/>
                        Inicialmente o projeto da Leme era atender outra cidade, contudo, após algumas petições, identificamos o grande potencial na cidade de <strong>Pedro Canário - ES</strong>, e assim, a pedido de inúmeros clientes decidimos pela implantação na cidade.<br/><br/>
                        Em <strong>14 de dezembro de 2020</strong> ligamos os motores do nosso navio e instalamos nosso primeiro cliente, desde então demos início a nossa tripulação que cresce a cada dia, construindo uma história de desafios, aprendizado, crescimento, conquista e satisfação.
                        </p>
                    </Text>
                    <SocialMedias>
                        <SocialButton href="https://instagram.com/lemeinternet" target="_blank">
                            <img src={InstagramIcon} alt="" />
                        </SocialButton>
                        <SocialButton href="https://facebook.com/lemeinternet" target="_blank">
                            <img src={FacebookIcon} alt="" />
                        </SocialButton>
                    </SocialMedias>
                </BlockText>
                <img src={LemeImage} alt="" />
            </AboutSessionContent>
        </AboutSessionContainer>
    )
}

export default AboutSession
