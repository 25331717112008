import { Link } from 'react-router-dom'

import LeftArrow from '../assets/icons/left-arrow.svg'

function LemeForm() {
  return (
      <div style={styles.html}>
          <Link to="/" style={styles.link}>
            <img src={LeftArrow} alt="Voltar" style={styles.logo}/>
          </Link>
          <iframe
            title="Formulário Leme"
            id="typeform-full"
            width="100%"
            height="100%"
            frameBorder="0"
            allow="camera; microphone; autoplay; encrypted-media;"
            src="https://form.typeform.com/to/ygG96SmK?typeform-medium=embed-snippet"
            style={styles.iframe}>
          </iframe>
      </div>
  );
}

export default LemeForm;

const styles = {
  html: {
    height: "100%",
    margin: "0",
    overflow: "hidden",
    background: "red"
  },

  iframe: {
      position: "absolute",
      left: "0",
      right: "0",
      bottom: "0",
      top: "0",
      border: "0"
  },

  link: {
    position: "absolute",
    top: "4rem",
    left: "5rem",
    opacity: "0.15",
    zIndex: "1002"
  },

  logo: {
    width: "26px" 
  }
}