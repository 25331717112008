import { useContext, useState } from 'react'
import Switch from 'react-switch'
import { ThemeContext } from 'styled-components'
import { Link } from 'react-scroll'

import { Container, Menu, MenuIconMobile } from '../styles/components/Header'
import MenuIcon from '../assets/icons/menu.svg'

const Header = ({ toggleTheme }) => {
    const { title, colors } = useContext(ThemeContext)
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    const openMenu = () => {
        if(!isMenuOpen) {
            setIsMenuOpen(true)
        } else {
            setIsMenuOpen(false)
        }
    }

    return (
        <Container>
            <div>
                <Link to="home" smooth={true} duration={600}>
                            <img src="/Leme-Logo.svg" alt="Leme Internet" />
                </Link>
                <Menu display={isMenuOpen ? "flex" : "none"}>
                    <ul>
                        <Link to="home" smooth={true} offset={-60} duration={600} onClick={openMenu}>
                            Home
                        </Link>
                        <Link to="planos" smooth={true} offset={-60} duration={600} onClick={openMenu}>
                            Planos
                        </Link>
                        <Link to="cobertura" smooth={true} offset={-190} duration={600} onClick={openMenu}>
                            Cobertura
                        </Link>
                        <Link to="sobre" smooth={true} offset={-60} duration={600} onClick={openMenu}>
                            Sobre a Leme
                        </Link>
                        <Link to="contato" smooth={true} offset={-60} duration={600} onClick={openMenu}>
                            Contato
                        </Link>

                        <Switch
                            onChange={toggleTheme}
                            checked={title === 'light'}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            height={10}
                            width={40}
                            handleDiameter={20}
                            offColor={colors.threeback}
                            onColor={colors.yellow}
                        />
                    </ul>
                </Menu>
                <MenuIconMobile type="button" onClick={openMenu}>
                    <img src={MenuIcon} alt="Menu" />
                </MenuIconMobile>
            </div>
        </Container>
    )
}

export default Header
