import { ThemeProvider } from 'styled-components'
import usePersistedState from './utils/usePersistedState'

import light from './styles/theme/light'
import dark from './styles/theme/dark'

import GlobalStyles from './styles/global'
import Routes from './routes'


function App() {
  const [theme, setTheme] = usePersistedState('theme', light);

  const toggleTheme = () => {
    setTheme(theme.title === 'light' ? dark : light);
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Routes theme={theme} toggleTheme={toggleTheme}/>
    </ThemeProvider>
  );
}

export default App;
