import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

import { Link } from 'react-scroll'

import CheckedIcon from '../assets/icons/checked.svg'
import ProfileIcon from '../assets/icons/profile-user.svg'


import { Button, ButtonsContainer } from '../styles/components/Buttons'

const Buttons = () => {
    const { colors } = useContext(ThemeContext)

    return (
        <ButtonsContainer>
            <Button background={colors.secondback} color={colors.white} borderRadius=".25rem 0 0 .25rem">
                <a href="https://central.lemeinternet.com.br" target="_blank" rel="noreferrer">
                    <img src={ProfileIcon} alt="Central do Cliente"/>
                    <p>Acesse a <span>Central do Cliente</span></p>
                </a>
            </Button>

            <Button background={colors.yellow} color="#fcfcfc" borderRadius="0 .25rem .25rem 0">
                <Link to="planos" smooth={true} offset={-60}>
                    <img src={CheckedIcon} alt="Contrate Agora" style={{marginRight: ".5rem"}}/>
                    <p>Contrate <span>Agora Mesmo</span></p>
                </Link>
            </Button>
        </ButtonsContainer>
    );
};

export default Buttons;
