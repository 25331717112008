import { PlansContentContainer } from '../styles/components/PlansContent'
import Plan from './Plan'

const PlansContent = () => {
    return (
        <PlansContentContainer>
            <Plan
                price={80}
                planNumber={50}
                planName="Básico"
                planDescription="Plano básico de qualidade para você"
            />
            <Plan
                price={100}
                planNumber={250}
                planName="Executivo"
                planDescription="Perfeito para você que trabalha Home Office"
            />
            <Plan
                price={150}
                planNumber={400}
                planName="Vip"
                planDescription="O melhor plano para a sua empresa"
            />
        </PlansContentContainer>
    )
}

export default PlansContent
